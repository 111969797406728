.cover {
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}
.cover > img{
  height: 70px;
}
.cover > h1 {
  font-family: "Charm", sans-serif;
  /* font-family: "Tangerine", sans-serif; */
  font-size: 100px;
  line-height: 95px;
  white-space: nowrap;
  overflow: hidden;
  margin:0 auto;
  animation: animated-text 5s steps(30, end) 0.5s 1 normal both;
}
.cover__initials {
  font-weight: 300;
}
.cover__dot{
  color: #ff914d;
  font-weight: normal;
}
.cover__namedecoration {
  text-decoration: underline;
  text-decoration-color: #ff914d;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 5px;
}
.cover > h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  margin-top: 40px;
  letter-spacing: 2px;
  animation: 2s ease-out 0s 1 slineinfrombottom;
}

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@media (max-width: 1400px) {
  .cover{
    height: 450px;
  }
}
@media (max-width: 1199px) {
  .cover{
    height: 350px;
  }
  .cover > h1 {
    font-size: 90px;
    line-height: 90px;
  }
}
@media (max-width: 991px) {
  .cover > h1 {
    font-size: 70px;
    line-height: 65px;
  }
}
@media (max-width: 767px) {
  .cover > h1 {
    font-size: 38px;
    line-height: 44px;
  }
  .cover > h4{
    margin-top: 10px;
  }
}
