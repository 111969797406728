* {
  margin: 0;
  scroll-behavior: smooth;
}
@import url('https://fonts.googleapis.com/css2?family=Nova+Round&family=Tangerine&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Charm&display=swap');
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #232323;
  background-image: linear-gradient(62deg, #3a3d40 0%, #181719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2a9e5', endColorstr='#2b94e5',GradientType=1 );
}
