h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: 0;
}
/* Header */
h5 {
  font-size: calc(20px + (32 - 20) * ((100vw - 300px) / (2000 - 300)));
  line-height: calc(28px + (40 - 28) * ((100vw - 300px) / (2000 - 300)));
  font-weight: 600;
  margin-top: 60px;
  color: #fff;
  font-family: "Charm", sans-serif;
}
/* Header Description */
h6 {
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (2000 - 300)));
  line-height: calc(24px + (32 - 24) * ((100vw - 300px) / (2000 - 300)));
  font-weight: 500;
  margin-bottom: 40px;
  color: rgba(255,255,255,0.5);
}

/*Sub-Header*/
.subheader {
  font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (2000 - 300)));
  line-height: calc(22px + (30 - 22) * ((100vw - 300px) / (2000 - 300)));
  font-weight: 500;
  font-family: "Nova Round",sans-serif ;
}
/*Sub-Header desctiption*/
.subheader__description {
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
  line-height: calc(24px + (28 - 24) * ((100vw - 300px) / (2000 - 300)));
  font-weight: 500;
  color: rgba(35,35,35,0.5);
}

@media (min-width : 710px) {
  .app__default {
    margin: 80px;
    border-radius: 4vw;
    border: 3px solid #fff;
    overflow:hidden;
  }
}


@keyframes slineinfrombottom {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slineinfromleft {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes shakeleftright {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    -webkit-transform: translateX(-10px);
  }

  40% {
    -webkit-transform: translateX(10px);
  }

  60% {
    -webkit-transform: translateX(-10px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes shakeleftright {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-10px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-2px);
  }
}

/* ball animation ends*/
