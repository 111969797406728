.scrollbutton{
    position: fixed;
    right: 40px;
    bottom: 20px;
    z-index: 1;

    transition-timing-function: ease-in;
    transition: all 0.5s;
    display: none;
}
.scrollbutton__hidden{
    display: block;
}
.scrollbutton > button{
    background-color: #ff914d;
    color: #fff;
}
.scrollbutton > button:hover{
    background-color: #ff914d;
    color: #fff;
    opacity: 0.6;
}