.group {
  box-shadow: -2.5rem 0 2rem -2rem #000;
  background: #f2f2f2;
  transition: all 0.5s ease-in;
  color: #232323;
}
/* .group:hover {
  animation: shake 0.5s;
  animation-iteration-count: 1;

  background: #e2a9e5;
  background: -moz-linear-gradient(-45deg, #e2a9e5 15%, #2b94e5 100%);
  background: -webkit-linear-gradient(-45deg, #e2a9e5 15%, #2b94e5 100%);
  background: linear-gradient(135deg, #e2a9e5 15%, #2b94e5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2a9e5', endColorstr='#2b94e5',GradientType=1 );
  color: #fff; 
}*/
.group__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 16px;
}

.group__headerdetails {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 16px;
}
.group__headerdetails > :last-child {
  margin: 10px 0px 0px;
}
.group > ul {
  list-style: none;
  padding: 0px 16px;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  font-weight: normal;
  color: inherit;
  font-family: "Nova Round",sans-serif ;
}
.group > ul > li {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 25px;
}
.group > ul > li > * {
  flex: 50%;
}
.group > ul > li > :last-child {
  text-align: right;
}

.group__progress {
  margin: 5px auto 20px;
  padding: 0;
  height: 5px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
}
.group__bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: #ff914d;
}
@media (max-width: 710px) {
  .group {
    color: #232323;
  }
}
@media (max-width: 530px) {
  .group__header {
    padding: 10px;
  }
  .group > ul {
    padding: 0px 10px;
  }
}
