.emailservice{
    margin-top: 60px;
    text-align: center;
}
.emailservice > form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.emailservice > form > input, textarea{
    margin: 10px;
    padding: 10px;
    width: 25%;
    outline: none;
    border:none;
    font-family: "Nova Round",sans-serif ;
}
.emailservice > form > input{
    text-align: center;
}
.emailservice > form > input, textarea{
    background-color: #f2f2f2;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.emailservice > form > input[type=submit]{
    width: auto;
    padding: 10px 50px;
    background-color: #ff914d;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
}
.emailservice > form > input[type=submit]:hover{
    opacity: 0.6;
    cursor: pointer;
}
.emailservice > form > textarea{
    height: 200px;
    resize: none;
    overflow-y: auto;
}
.emailservice > form > input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #ff914d;
    opacity: 0.7;
    text-align: center;
}
.emailservice > form > input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #ff914d;
   opacity: 0.7;
   text-align: center;
}
.emailservice > form > input::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #ff914d;
   opacity: 0.7;
   text-align: center;
}
.emailservice > form > input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #ff914d;
   opacity: 0.7;
   text-align: center;
}
.emailservice > form > input:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color:    #ff914d;
  opacity: 0.7;
  text-align: center;
}


.emailservice > form > textarea::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #ff914d;
    opacity: 0.7;
}
.emailservice > form > textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #ff914d;
   opacity: 0.7;
}
.emailservice > form > textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #ff914d;
   opacity: 0.7;
}
.emailservice > form > textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #ff914d;
   opacity: 0.7;
}
.emailservice > form > textarea:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color:    #ff914d;
  opacity: 0.7;
}

@media(max-width:1600px){
    .emailservice > form > input, textarea{
        width: 27%;
    }
}
@media(max-width:1500px){
    .emailservice > form > input, textarea{
        width: 30%;
    }
}
@media(max-width:1300px){
    .emailservice > form > input, textarea{
        width: 33%;
    }
}
@media(max-width:1200px){
    .emailservice > form > input, textarea{
        width: 37%;
    }
}
@media(max-width:1000px){
    .emailservice > form > input, textarea{
        width: 40%;
    }
}
@media(max-width:900px){
    .emailservice > form > input, textarea{
        width: 45%;
    }
}
@media(max-width:800px){
    .emailservice > form > input, textarea{
        width: 50%;
    }
}
@media(max-width:700px){
    .emailservice > form > input, textarea{
        width: 55%;
    }
}
@media(max-width:600px){
    .emailservice > form > input, textarea{
        width: 60%;
    }
}
@media(max-width:500px){
    .emailservice > form > input, textarea{
        width: 65%;
    }
}
@media(max-width:400px){
    .emailservice > form > input, textarea{
        width: 75%;
    }
}
@media(max-width:300px){
    .emailservice > form > input, textarea{
        width: 90%;
    }
}