.editblog {
  max-width: 1000px;
  margin: auto;
  text-align: center;
}
.editblog > h3 {
  color: #ff914d;
  font-weight: 700;
  margin-top: 5%;
  margin-bottom: 2%;
}
.editblog > input {
  width: 60%;
  margin-bottom: 1%;
  padding: 10px;
  border: none;
  outline-width: 0;
}
.editblog__loading {
  width: 100%;
  height: 100vh;
  padding-left: 45vw;
  padding-top: 45vh;
  background: #fff;
}
.editblog__blogimage{
  color: #fff;
  font-weight: 700;
}
.editblog__blogimageuploads,
.editblog__seoimageuploads{
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 0.5%;
  padding-top: 0.5%;
}
.editblog__page{
  height: 100vh;
  overflow: auto;
}