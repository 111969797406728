.skill {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.skill > h5 {
  margin-bottom: 5px;
}
.skill__group {
  -moz-column-count: 2;
  -moz-column-gap: 40px;
  -moz-column-width: auto;
  -webkit-column-count: 2;
  -webkit-column-gap: 40px;
  -webkit-column-width: auto;
  column-count: 2;
  column-gap: 40px;
  column-width: auto;
  margin: 0px 30%;
  

  opacity: 0;
  transform: translate( 0,250px);
  /* visibility: hidden; */
  transition: transform 1s ease-in;

}

.skill__group > * {
  display: inline-block;
  margin: 10px 0px;
  width: 100%;
  height: 425px;
  padding: 10px 0;
  border-radius: 5%;
}
.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
@media (max-width: 1600px) {
  .skill__group {
    margin: 0px 330px;
  }
}
@media (max-width: 1500px) {
  .skill__group {
    margin: 0px 270px;
  }
}
@media (max-width: 1400px) {
  .skill__group {
    margin: 0px 240px;
  }
}
@media (max-width: 1300px) {
  .skill__group {
    margin: 0px 200px;
  }
}
@media (max-width: 1200px) {
  .skill__group {
    margin: 0px 150px;
  }
}
@media (max-width: 1100px) {
  .skill__group {
    margin: 0px 100px;
  }
}
@media (max-width: 1000px) {
  .skill__group {
    margin: 0px 100px;
  }
}
@media (max-width: 900px) {
  .skill__group {
    -moz-column-count: 1;
    -moz-column-gap: 0;
    -moz-column-width: 100%;
    -webkit-column-count: 1;
    -webkit-column-gap: 0;
    -webkit-column-width: 100%;
    column-count: 1;
    column-gap: 1;
    column-width: 100%;
  }
}
@media (max-width: 530px) {
  .skill__group > * {
    display: inline-block;
    margin: 0 0 20px;
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 450px) {
  .skill__group {
    margin: 0px 30px;
  }
}
