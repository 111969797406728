.ql-header,
.ql-toolbar {
  background-color: #fff !important;
}
.ql-container{
    background-color: #fff;
    height: 550px;
    overflow-y: auto;
}
@media (max-width: 1600px) {
  .ql-container{
    height: 525px;
  }
}
@media (max-width: 1500px) {
  .ql-container{
    height: 500px;
  }
}