.introduction {
  margin-top: 40px;
  padding: 10px 40px 10px;
  display: flex;
  justify-content: space-evenly;
  background-color: #F0E2C8;
  overflow: hidden;
  animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */

}
.introduction > img {
  border: none;
  height: 600px;
  width: 500px;
  background-color: #ff914d;
  /* border-radius: 30% 70% 70% 30% / 30% 44% 56% 70% ; */
  /* border-radius: 43% 57% 85% 15% / 30% 44% 56% 70% ; */
  /* border-radius: 43% 57% 69% 31% / 37% 36% 64% 63% ; */
  border-radius: 30% 70% 70% 30% / 30% 46% 54% 70% ;
  /* filter: drop-shadow(-3rem 1.2rem 1.25rem rgba(0, 0, 0, 0.5)); */
  /* filter: drop-shadow(-3rem 1.2rem 0.25rem rgba(0, 0, 0, 0.5)); */
  /* animation: 2s ease-out 0s 1 slineinfromleft; */
}
.introduction > img::before {
    filter: drop-shadow(-3rem 1.2rem 1.25rem rgba(0, 0, 0, 0.5));
}
.introduction__details {
  font-weight: 600;
  max-width: 500px;
  color: #232323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.introduction__details > h2 {
  font-weight: 600;
  color: #232323;
  font-size: 25px;
  line-height: 45px;
  letter-spacing: 1px;
  font-family: "Charm", sans-serif;
}
.introduction__details > h3 {
  font-weight: 400;
  color: #6f6f6f;
  margin: 25px 0 60px;
  font-size: 20px;
  line-height: 26px;
  font-family: "Nova Round",sans-serif ;
}
.introduction__highlight {
  text-decoration: underline #ff914d;
}
.introduction__strongpoints {
  display: flex;
  justify-content: space-around;
  font-family: "Nova Round",sans-serif ;
}
.introduction__strongpoint1 > h4,
.introduction__strongpoint2 > h4 {
  font-weight: 600;
  margin-bottom: 5px;
  color: #232323;
}
.introduction__strongpoint1 > p,
.introduction__strongpoint2 > p {
  font-weight: 400;
  color: #6f6f6f;
  line-height: 24px;
}
@media (max-width: 767px) {
  .introduction__details > h2 {
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 17px;
    padding: 0;
    letter-spacing: 0;
    text-align: center;
  }
  .introduction__details > h3{
    text-align: center;
  }
  .introduction__strongpoints {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .introduction__strongpoint1,
  .introduction__strongpoint2 {
    padding: 10px 0;
  }
  .introduction__strongpoint1 > h4,
  .introduction__strongpoint2 > h4 {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
  }
  .introduction__strongpoint1 > p,
  .introduction__strongpoint2 > p {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
  }
  .introduction__details{
    max-width: 90%;
  }
}
@media (max-width: 991px) {
  .introduction {
    margin-top: 0px;
    padding: 40px 10px;
  }
  .introduction > img {
    display: none;
  }
  .introduction__details > h2 {
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 18px;
    padding: 0;
    letter-spacing: 0;
  }
}
@media (max-width: 1199px) {
  .introduction__details > h2 {
    font-size: 22px;
    line-height: 27px;
  }
  .introduction__details > h3 {
    font-size: 18px;
    line-height: 24px;
  }
}
@media(min-width: 900px){
  .introduction__details > h2{
    filter: drop-shadow(-1rem 0.5rem 0.2rem rgba(0, 0, 0, 0.7));
  }
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}