.downloader {
  width: 500px;
  min-height: 128px;
  position: fixed;
  left: 18px;
  bottom: 18px;
  max-height: 700px;
  overflow-y: auto;
  border: 1px solid lightgray;
  background: lightgray;
  z-index: 9;
  box-shadow: 0 0 5px 2px #232323;

}
.downloader .card-header {
  color: #fff;
  background-color: #ff914d;
  padding: 7px 20px;
}
.downloader .card .list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
.list-group-item {
  list-style: none;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3%;
}
.downloader__success{
    position: absolute;
    right: 10%;
}
.downloader__subheader {
  font-weight: 600;
}
.group__progress {
  margin: 5px auto 20px;
  padding: 0;
  height: 5px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
}
.group__bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: #ff914d;
}
