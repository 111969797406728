.services {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services > h5 {
  margin-bottom: 5px;
}
.services__details {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 1100px) {
  .services__details {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
  }
  .services__details > * :last-child{
    grid-column: 1 / span 2;
  }
}

@media (max-width: 750px) {
  .services__details {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }

}