.postpage {
  width: 80%;
  min-height: 100vh;
  overflow-y: auto;
  padding: 0 10%;
  background-color: #fff;
}
.postpage > h3 {
  text-align: right;
  font-weight: 500;
  padding-right: 10px;
}
.postpage > :first-child {
  /* color: #ff914d;
  font-weight: 800; */
  margin-top: 5%;
}
.postpage > h2 {
  margin-top: 1%;
  text-align: left;
  font-weight: 800;
  padding-bottom: 1%;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.postpage > h2 > :first-child {
  font-size: 40px;
  font-weight: 500;
}
.postpage > h2 > :last-child {
  float: right;
  font-weight: 500;
  padding-right: 10px;
  justify-content: flex-end;
}
.postpage > h2 > :last-child:hover {
  cursor: pointer;
}
.postpage__panel {
  color: #454545;
  text-align: left;
  padding: 10px 10px;
}
.postpage__auther{
  display: flex;
  align-items: center;
  padding-bottom: 0.5%;
}
.postpage__autherimage{
  padding: 0 5px;
  border: 0 solid lightgray;
  border-radius: 50%;
  overflow: hidden;
}
.postpage__autherimage>img{
  height: 50px;
  width: 40px;
}
.postpage__autherdetails > :first-child{
  opacity: 0.7;
}
.postpage__autherdetails > :last-child{
  font-weight: 600;
  opacity: 0.5;
  font-size: 0.8em;
}
.postpage__sociallinks{
  display: flex;
  flex:1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
.postpage__sociallinks *{
  padding: 3px;
}
.postpage__sociallinks *:hover{
  opacity: 0.7;
  cursor: pointer;
}
.postbase__blogimage> img{
  width: 100%;
  height: 60vh;
}