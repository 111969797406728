.bottomline {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
  margin-top: 60px;
  margin-bottom: 40px;
  background: #F0E2C8;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.bottomline__quote {
  display: flex;
  flex-direction: column;
}
.bottomline__quote > :first-child {
  margin-bottom: 5px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 0;
  font-family: "Charm", sans-serif;

  animation: shakeleftright 10s ease-in-out;
  animation-iteration-count: infinite;
  color: #232323;
}
.bottomline__quote > :last-child {
  font-size: 18px;
  line-height: 23px;
  color: #6f6f6f;
  font-weight: 400;
  font-family: "Nova Round",sans-serif ;
}
.bottomline__contacts{
  font-family: "Nova Round",sans-serif ;
}
.bottomline__contacts > :first-child {
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  font-weight: 400;
  margin-bottom: 5px;
  color: #232323;

}
.bottomline__contacts > :last-child {
  color: #6f6f6f;
  background-color: transparent;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  font-weight: 400;
  text-decoration: none;
}
.bottomline__contacts > *:hover {
  cursor: pointer;
  color: #ff914d;
}

@media (max-width: 1199px) {
  .bottomline__quote > :first-child {
    font-size: 27px;
    line-height: 32px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
  }
}
@media (max-width: 991px) {
  .bottomline__quote > :first-child {
    font-size: 24px;
    line-height: 30px;
    /* margin: 0 0 18px; */
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
  }
}
@media (max-width: 767px) {
  .bottomline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
  }
  .bottomline__quote > :first-child {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 10px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
  }
  .bottomline__contacts {
    margin-top: 35px;
    margin-bottom: 30px;
  }
  .bottomline__contacts > :first-child {
    text-align: center;
  }
}
@media (max-width: 480px) {
  .bottomline {
    margin-left: 3%;
    margin-right: 3%;
  }
}
