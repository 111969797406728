.blogpage {
  padding-left: 10%;
  padding-right: 10%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.blogpage__panel{
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.blogpage > h2 {
  color: #ff914d;
  text-align: center;
  margin-top: 1%;
}
.blogpage__auther{
    color: #ff914d;
    font-weight: 900;
}
.blogpage *,
*::before,
*::after {
  box-sizing: border-box;
}

.blogpage a {
  color: #454545;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
