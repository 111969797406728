.downloadcv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.downloadcv > button{
    margin: 10px;
    padding: 10px;
    width: 25%;
    outline: none;
    border:none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: lightgray;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    width: auto;
    padding: 10px 50px;
    background-color: #ff914d;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    font-family: "Nova Round",sans-serif ;
}
.downloadcv > button[type=submit]:hover{
    opacity: 0.6;
    cursor: pointer;
}
.downloadcv__downicon{
    animation: MoveUpDown 1.5s linear infinite;
}