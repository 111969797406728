.service {
  margin: 0 35px;
  width: 200px;
  height: 250px;
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;

  box-shadow: -2.5rem 0 2rem -2rem #000;

  background: #f2f2f2;
  transition: all 0.5s ease-in;
  color: #232323;
  border-radius: 5%;
}
.service:hover {
  animation: shake 0.5s;
  animation-iteration-count: 1;

  background: #e2a9e5;
  background: -moz-linear-gradient(-45deg, #e2a9e5 15%, #2b94e5 100%);
  background: -webkit-linear-gradient(-45deg, #e2a9e5 15%, #2b94e5 100%);
  background: linear-gradient(135deg, #e2a9e5 15%, #2b94e5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2a9e5', endColorstr='#2b94e5',GradientType=1 );
  color: #fff;
}
.service__link {
  display: flex;
  align-items: center;
  transition-timing-function: ease-in;
  transition: all 0.5s;
}
.service__link > span {
  padding-right: 5px;
}
.service__link:hover {
  cursor: pointer;
  opacity: 1;
  color: #ff914d;
}
.service__link:hover > span {
  padding-right: 10px;
}
#transition-modal-title {
  font-family: "Charm", sans-serif;
  font-size: calc(
    20px + (32 - 20) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  line-height: calc(
    28px + (40 - 28) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  font-weight: 600 !important;
  margin-bottom: 20px;
  border-bottom: 1px solid lightgray;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
#transition-modal-description {
  list-style: none;
  padding: 0;
  font-family: "Nova Round",sans-serif ;
}
#transition-modal-description li {
  font-size: calc(
    14px + (18 - 14) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  line-height: calc(
    22px + (30 - 22) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  color: #232323;
  opacity: 0.8;
}
#transition-modal-description li:before {
  content: "✔";
  color: #ff914d;
  font-size: 24px;
  padding-right: 3px;
}
.MuiIconButton-root {
  padding: 0 !important;
  color: #232323 !important;
  font-size: calc(
    20px + (32 - 20) * ((100vw - 300px) / (2000 - 300))
  ) !important;
}
@media (max-width: 1100px) {
  .service {
    margin: 20px 40px;
  }
}
@media (max-width: 1000px) {
  .service {
    margin: 20px 40px;
  }
}

@media (max-width: 900px) {
  .service {
    margin: 20px 20px;
  }
}
@media (max-width: 800px) {
  .service {
    margin: 10px 10px;
  }
}
@media (max-width: 710px) {
  .service {
    color: #232323;
  }
}
@media (max-width: 600px) {
  .makeStyles-paper-86 {
    width: 440px !important;
  }
}
@media (max-width: 530px) {
  .service {
    height: 150px;
  }
  .makeStyles-paper-48,
  .makeStyles-paper-36 {
    padding: 16px 5px 16px !important;
  }
}
@media (max-width: 340px) {
  .makeStyles-paper-48,
  .makeStyles-paper-36 {
    padding: 16px 3px 16px !important;
  }
}
