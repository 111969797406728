.professionaljourney {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.professionaljourney > h5 {
  margin-bottom: 5px;
}
.professionaljourney__details {
  width: 44%;
  max-width: 800px;
}
.makeStyles-paper-34,
.makeStyles-paper-16,
.makeStyles-paper-52,
.makeStyles-paper-58,
.makeStyles-paper-64{
  border: none !important;
  border-radius: 5% !important;
}
.MuiTypography-h6 {
  font-family: "Nova Round",sans-serif  !important;
  font-size: calc(
    16px + (22 - 16) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  line-height: calc(
    22px + (30 - 22) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}
.MuiTypography-body1,
.MuiTypography-body2 {
  font-size: calc(
    14px + (18 - 14) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  line-height: calc(
    24px + (28 - 24) * ((100vw - 300px) / (2000 - 300))
  ) !important;
  font-weight: 500 !important;
}
.MuiTypography-colorTextSecondary {
  color: rgba(35, 35, 35, 0.5) !important;
}
.makeStyles-paper-28,
.makeStyles-paper-52,
.makeStyles-paper-58,
.makeStyles-paper-152 {
  padding: 10px 16px !important;
}
.makeStyles-paper-16 {
  border: none !important;
}
.MuiPaper-root {
  box-shadow: -2.5rem 0 2rem -2rem #000 !important;
  background: #f2f2f2 !important;
  transition: all 0.5s ease-in !important;
}
/* .MuiPaper-root:hover {
  animation: shake 0.5s;
  animation-iteration-count: 1;

  background: #e2a9e5 !important;
  background: -moz-linear-gradient(
    -45deg,
    #e2a9e5 15%,
    #2b94e5 100%
  ) !important;
  background: -webkit-linear-gradient(
    -45deg,
    #e2a9e5 15%,
    #2b94e5 100%
  ) !important;
  background: linear-gradient(135deg, #e2a9e5 15%, #2b94e5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2a9e5', endColorstr='#2b94e5',GradientType=1 ) !important;
  color: #fff !important; 
}*/
.MuiTimelineDot-outlinedSecondary{
  color: #fff !important;
  border-color: #ff914d !important;
}
.MuiTimelineDot-defaultSecondary{
  background-color: #ff914d !important;
}
@media (max-width: 1600px) {
  .professionaljourney__details {
    width: 45%;
  }
}
@media (max-width: 1500px) {
  .professionaljourney__details {
    width: 50%;
  }
}
@media (max-width: 1400px) {
  .professionaljourney__details {
    width: 65%;
  }
}
@media (max-width: 1300px) {
  .professionaljourney__details {
    width: 75%;
  }
}
@media (max-width: 1200px) {
  .professionaljourney__details {
    width: 85%;
  }
}
@media (max-width: 1000px) {
  .professionaljourney__details {
    width: 85%;
  }
}
@media (max-width: 900px) {
  .professionaljourney__details {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .professionaljourney__details {
    width: 95%;
  }
}

@media (max-width: 750px) {
  .makeStyles-paper-4 {
    padding: 3px;
  }
  .MuiTimelineItem-root {
    margin-left: -86%;
  }
}
@media (max-width: 710px) {
  .MuiPaper-root {
    color: #232323 !important;
  }
}
@media (max-width: 460px) {
  .professionaljourney__details {
    width: 100%;
  }
}
