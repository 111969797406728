.create {
  max-width: 700px;
  margin: auto;
  height: 100vh;
  overflow-y: hidden;
  text-align: center;

}
.create > h3 {
  color: #ff914d;
  font-weight: 700;
  margin-top: 5%;
  margin-bottom: 2%;
}
.create > input{
  width: 60%;
  margin-bottom: 1%;
  padding: 10px;
  border: none;
  outline-width: 0;
}
.create__submit {
  background-color: #fff !important;
  color: #ff914d !important;
  font-weight: 900 !important;
}
.create__submit:hover {
  opacity: 0.8;
}
